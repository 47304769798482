import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[textareaCheck]'
})

export class TextareaCheckDirective {

  @Input() isTextareaCheck: boolean;

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(`[^'"]`).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(/\t/g, '').replace(/["']/g, '');
    document.execCommand('insertHTML', false, pasteData);
  }

}