import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable()
export class CommonService {
    constructor(
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
    }

    setDateStr($event) {
        if($event.target.value == ''){
            $event.target.value = null;
        } else {
            $event.target.value = new Date($event.target.value);
        }
    }

    displayFn(column: string, data, id?: any): string | object | undefined {
        let item = data ? data.find(a => a.id == id) : null
        return item ? (item[column] ? item[column] : '') : null;
      }
    displayObjFn(column: string, data?: any): string | undefined {
        return data ? data[column] : undefined;
      }
    filterData(value, column, data): any[] {
      let filterValue = value.toLowerCase();
      let newData = []
      let number = 0 
      let length = data.length
      for (var i = 0; i < length; i++) {
        if(data[i][column].toLowerCase().includes(filterValue)){
          newData.push(data[i])
          number++
        }
        if(number >= 50) break
      }
      return newData
    }

    calculateDataColumn(data, column){
        return data.reduce((sum, curr) => sum + (curr[column] ? curr[column] : 0), 0);
    }
    calculateDataColumnType(data, column, type) {
        return data.reduce((sum, curr) => sum + (curr[column] && curr[column][type] ? curr[column][type] : 0), 0);
    }
    calculateDataTotal(data) {
        return data.reduce((sum, item) => sum + (item.amount ? Math.round(item.volume * item.amount * item.ex_rate)  : 0) , 0);
    }
    calculateDataTotalTax(data) {
        return data.reduce((sum, item) => sum + (item.amount ? Math.round(item.volume * (item.amount + item.amount * item.tax/100)) : 0) , 0);
    }
    calculateDataTotalTaxExchange(data) {
        return data.reduce((sum, item) => sum + (item.amount ? Math.round(item.volume * (item.amount + item.amount * item.tax/100) * item.ex_rate) : 0) , 0);
    }
    findItem(data, column, value): object | undefined {
        if(!data){
            return null;
        } else {
            let item = data.find(a => a[column] == value)
            return item ? item : null;
        }
    }

    inWords (num) {
        let a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
        let b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
      let n = ('000000000000' + num).substr(-12).match(/^(\d{1})(\d{2})(\d{1})(\d{2})(\d{1})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; 
      let str = '';
      str += (n[1] != '0') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'hundred ' : '';
      str += (n[2] != '00') ? ((Number(n[2]) < 10 && Number(n[1]) > 0 ? '' : '' ) + (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[1][1]])) + 'bilion ' : ((n[1] != '0') ? 'bilion ' : '');
      str += (n[3] != '0') ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'hundred ' : (str != '' && n[4] != '00' ? '' : '');
      str += (n[4] != '00') ? ((Number(n[4]) < 10 && str != '' ? '' : '' ) + (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]])) + 'milion ' : ((n[3] != '0') ? 'milion ' : '');
      str += (n[5] != '0') ? (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'hundred ' : (str != '' && n[6] != '00' ? '' : '');
      str += (n[6] != '00') ? ((Number(n[6]) < 10 && str != '' ? '' : '' ) + (a[Number(n[6])] || b[n[6][0]] + ' ' + a[n[6][1]])) + 'thousand ' : ((n[5] != '0') ? 'thousand ' : '');
      str += (n[7] != '0') ? (a[Number(n[7])] || b[n[7][0]] + ' ' + a[n[7][1]]) + 'hundred ' : (str != '' && n[8] != '00' ? '' : '');
      str += (n[8] != '00') ? ((Number(n[8]) < 10 && str != '' ? '' : '' ) + (a[Number(n[8])] || b[n[8][0]] + ' ' + a[n[8][1]])) : (str != '' ? '' : '');
      return str;
    }
    getExcel(data, header, name){
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet(name);
        let headerRow = worksheet.addRow(header);
        headerRow.height = 52;
        worksheet.getRow(1).font = { name: 'Arial', size: 10, bold: true , italic: false };

        data.map(d => {
          let row = worksheet.addRow(d);
          row.height = 45;
          let length = d.length;
          for (let i = 1; i <= length; i++){
            row.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            row.getCell(i).font = { name: 'Arial', size: 10, bold: true , italic: false };
            row.getCell(i).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
          }
        });
          workbook.xlsx.writeBuffer().then((data) => {
              let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, name + 'List.xlsx');
            })
    }
}
