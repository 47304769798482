import { Injectable }       from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
}                           from '@angular/router';
import { AuthService }      from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  modul = [
    {
      name: "Admin", 
      value: "admin",
      link: [
        {url: 'my-profile',type: 'read_data', p: true},
      ]
    },
    {
      name: "Dashboards", 
      value: "dashboards",
      link: [
        {url: 'search',type: 'read_data', p: true},
      ]
    },
    {
      name: "Partner", 
      value: "partner",
      link: [
        {url: 'supplier',type: 'read_data', p: null},
        {url: 'supplier-report',type: 'read_data', p: null},
        {url: 'sel-vendor',type: 'read_data', p: null},
        {url: 'sel-contract',type: 'read_data', p: null},
      ]
    },
    {
      name: "Human Resources", 
      value: "human-resources",
      link: [
        {url: 'user_time',type: 'read_data', p: null},
      ]
    },
    {
      name: "Sel", 
      value: "sel",
      link: [
        {url: 'sel-shipment',type: 'read_data', p: null},
        {url: 'sel-report',type: 'read_data', p: null},
        {url: 'sel-book',type: 'read_data', p: null},
        
      ]
    },
    {
      name: "Cms", 
      value: "cms",
      link: [
        {url: 'customer',type: 'read_data', p: null},
        {url: 'customer-report',type: 'read_data', p: null},
        {url: 'quote',type: 'read_data', p: null},
        
      ]
    },
    {
      name: "Settings", 
      value: "setting",
      link: [
        {url: 'vessel',type: 'read_data', p: null},
        {url: 'voyage',type: 'read_data', p: null},
        {url: 'currency',type: 'read_data', p: null},
        {url: 'vessel',type: 'read_data', p: null},
        {url: 'price',type: 'read_data', p: null},
        {url: 'commodity',type: 'read_data', p: null},
        {url: 'package',type: 'read_data', p: null},
        {url: 'package-type',type: 'read_data', p: null},
        {url: 'tarrif',type: 'read_data', p: null},
        {url: 'sel-tarrif',type: 'read_data', p: null},
        {url: 'move-type',type: 'read_data', p: null},
        {url: 'truck-point',type: 'read_data', p: null},
      ]
    },
    {
      name: "Service", 
      value: "service",
      link: [
        {url: 'fcl',type: 'read_data', p: null},
        {url: 'fcl-price',type: 'read_data', p: null},
        {url: 'fcl-transport',type: 'read_data', p: null},
      ]
    },
  ];
  url;
  constructor(private authService: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  getPermission(url){
    let arr = url.split('/');
    let modul = this.modul.find(item => item.value == arr[2]);
    let link = modul.link.find(item => item.url == arr[3]);
    // check duong dan co ton tai khong
    if(!link) return 0; 

    // check duong dan co uu tien khong - uu tien = ko check permission database
    if(link.p) return 1; 

    // check permission database
    let check = this.authService.getPermission(modul.value, link.type);
    
    if(check != 1) check = 0;
    return check;
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn) {
      let check = this.getPermission(url);
      // neu permission true
      if(check == 1){
        this.url = url;
        return true;
      }
      alert('No permission/Action: ' + url);
      return false;
    } else {
      this.authService.redirectUrl = url;
      // Navigate to the login page with extras
      this.router.navigate(['/login']);
      return false;
    }
  }
}