import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getByCol',
    pure: false
})
export class GetByColPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} value
     * @param {number} id
     * @param {string} property
     * @returns {any}
     */
    transform(value: any[], arr: any, property: string): any
    {

        const foundItem = value.find(item => {
            if ( item[arr[0]] !== undefined )
            {
                return item[arr[0]] === arr[1];
            }

            return false;
        });
        if ( foundItem )
        {
            return foundItem[property];
        }
    }
}
