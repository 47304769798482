import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService }      from 'app/auth.service';
import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';

import { map, catchError} from 'rxjs/operators';

@Injectable()
export class LoginService
{
    onLoginChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _http:Http,
        private _httpClient: HttpClient,
        private authService: AuthService
    )
    {
        // Set the defaults
        this.onLoginChanged = new BehaviorSubject({});
    }

    private extractData(res: Response) {
      let body = JSON.parse(res['_body']);
      return body;
    }
    private handleError (error: Response | any) {
      alert(error);
      console.error(error.message || error);
      return throwError(error.status);
    }

    login(user): Promise<any>
    {
        return new Promise((resolve, reject) => {
            let cpHeaders = new Headers({ 
                'Content-Type': 'application/json' ,
                'Authorization': "Basic " + this.authService.checkValue
            });
            let options = new RequestOptions({ headers: cpHeaders });
            this._http.post(this.authService.apiUrl + '/sel/login/sale', user, options)
                .subscribe((response: any) => {
                    this.onLoginChanged.next(this.extractData(response));
                    resolve(this.extractData(response));
                }, reject);
        });
    }
    
}
