import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {   id       : 'dashboards',
        title    : 'Dashboards',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'search',
                title: 'Search',
                type : 'item',
                url  : '/apps/dashboards/search/home',
                icon     : 'dashboard',
                
            },
        ]
    },
    
    {   id       : 'sel',
        title    : 'Sel',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'sel-book',
                title: 'Booking',
                type : 'item',
                url  : 'apps/sel/sel-book/get-book',
                icon     : 'dashboard',
            },
            {
                id   : 'sel-book2',
                title: 'Booking with Price',
                type : 'item',
                url  : 'apps/sel/sel-book/get-book2',
                icon     : 'dashboard',
            },
            {
                id   : 'sel-book-list',
                title: 'Booking list',
                type : 'item',
                url  : '/apps/sel/sel-book/book-list',
                icon     : 'dashboard',
                
            },
            {
                id   : 'sel-book-space',
                title: 'Booking space',
                type : 'item',
                url  : '/apps/sel/sel-book/book-space',
                icon     : 'dashboard',
                
            },
            {
                id   : 'sel-shipment-list2',
                title: 'Shipment list2',
                type : 'item',
                url  : 'apps/sel/sel-book/shipment-list2',
                icon     : 'dashboard',
                
            },
            {
                id       : 'sel_invoice_cost',
                title    : 'Sel invoice cost(vessel)',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-cost'
            },
            {
                id       : 'sel_invoice_cost2',
                title    : 'Sel invoice cost(time)',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-cost2'
            },
        ]
    },
    /*
    {   id       : 'sale',
        title    : 'Sales Function',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'customerfb',
                title: 'New Customer',
                type : 'item',
                url  : '/apps/sales-function/customerfb/list',
                icon     : 'account_box',
            },
            {
                id       : 'customer',
                title    : 'Customer',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/sales-function/customer/list'
            },
            {
                id       : 'customer-report',
                title    : 'Customer report',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/sales-function/customer-report'
            },
        ]
    },
    */
    
    {   id       : 'cms',
        title    : 'CMS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'customer',
                title    : 'Customer',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/cms/customer/list'
            },
            {
                id   : 'sel-quote',
                title: 'Quote',
                type : 'item',
                url  : 'apps/cms/quote/list',
                icon     : 'dashboard',
            },
            {
                id       : 'customer-report',
                title    : 'Customer report',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/cms/customer-report'
            },
        ]
    },
    {
        id       : 'partner',
        title    : 'Partner Control',
        type     : 'group',
        icon     : 'local_shipping',
        children : [
            {
                id       : 'supplier',
                title    : 'Supplier',
                type     : 'item',
                icon     : 'local_shipping',
                url  : '/apps/partner/supplier/list'
            },
            {
                id       : 'supplier2',
                title    : 'Supplier Report',
                type     : 'item',
                icon     : 'local_shipping',
                url  : '/apps/partner/supplier-report'
            },
            {
                id       : 'sel-vendor',
                title    : 'Sel vendor',
                type     : 'item',
                icon     : 'note',
                url  : '/apps/partner/sel-vendor/list'
            },
            {
                id       : 'sel-contract',
                title    : 'Sel contract',
                type     : 'item',
                icon     : 'note',
                url  : '/apps/partner/sel-contract/list'
            },
        ]
    },
    {
        id       : 'setting',
        title    : 'Setting Control',
        type     : 'group',
        icon     : 'settings',
        children : [
            {
                id       : 'vessel',
                title    : 'Vessel',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/setting/vessel/list'
            },
            {
                id       : 'voyage',
                title    : 'Voyage',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/setting/voyage/list'
            },
            {
                id   : 'sel-tarrif',
                title: 'Sel Tarrif',
                type : 'item',
                url  : 'apps/setting/sel-tarrif/list',
                icon     : 'dashboard',
            },
        ]
    },
    {
        id       : 'service',
        title    : 'Service',
        type     : 'group',
        icon     : 'list_alt',
        children : [
            {
                id       : 'fcl2',
                title    : 'FCL 2',
                type     : 'item',
                icon     : 'list_alt',
                url  : '/apps/service/fcl/list2/1'
            },
        ]
    },
    {
        id       : 'humanResources',
        title    : 'Human Resources',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'user_time',
                title: 'Time shift',
                type : 'item',
                url  : '/apps/human-resources/user_time',
                icon     : 'dashboard',
                
            },
        ],
    },
];
